import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { FaArrowLeft } from 'react-icons/fa';
import Layout from '../components/layout';
import { parseHtmlImages } from '../components/utitlies/utility';

function EventAncmt({ data }) {
  const eventAncmtContent = data.medcoeEventAncmtJson;
  const docNames = data.allMedcoeDocNameJson.edges;
  // console.log('**** - CONTENT:', publicContent.PageContent);
  // console.log('doc names', docNames);

  let backLink = '';

  switch (eventAncmtContent.Portlet_ID) {
    case 17:
      backLink = (
        <Link to='/upcoming-events'>
          <FaArrowLeft /> All Events
        </Link>
      );
      break;
    case 36:
      backLink = (
        <Link to='/announcements'>
          <FaArrowLeft /> Hot Topics/News
        </Link>
      );
      break;
    default:
      backLink = '';
      break;
  }

  const newHtml = parseHtmlImages(eventAncmtContent.Description, docNames);

  return (
    <Layout>
      <div className='grid-container'>
        <div
          className='usa-layout-docs__main desktop:grid-col-12 usa-prose'
          css={css`
            padding: 2rem 0;
            min-height: 400px;
          `}
        >
          <div dangerouslySetInnerHTML={{ __html: newHtml }} />
          {backLink}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query getOneEventAncmt($id: Int) {
    medcoeEventAncmtJson(PublicEvent_ID: { eq: $id }) {
      Portlet_ID
      Description
      Event
    }
    allFile {
      edges {
        node {
          base
          publicURL
        }
      }
    }
    allMedcoeDocNameJson {
      edges {
        node {
          document_name
          id
          docGuid
        }
      }
    }
  }
`;

export default EventAncmt;
